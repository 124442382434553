import { App } from "../../app.module";
import { BasePage } from "../../baseclasses/BasePage";
import { ProjectPage } from "../../Pages/ProjectPage/ProjectPage"
import { SectionPage } from "../../Pages/SectionPage/SectionPage";
import { ElementPage } from "../../Pages/ElementPage/ElementPage";
import { Utilities } from "../../Utilities/Utilities";
import { Fetcher } from "../../Utilities/Fetcher";


export class Tree extends BasePage {
	//
	private autoUpdateTimeout: number;
	private autoUpdateDelay: number = 60000;	// 60 seconds


	// DOMElements
	private buttonProjectsUpdate: HTMLElement;
	private set loadingHidden(hidden: boolean) {
		this.buttonProjectsUpdate.classList.toggle("load", !hidden);
	}
	//
	private filter_input: HTMLInputElement;
	private ulList: HTMLUListElement;
	private ulTree: HTMLUListElement;
	//
	private data: any;

	constructor(public app: App) {
		super(app);
	}

	initialize(): void {
		// DOM ELEMENTS
		this.rootElement = (<HTMLElement>this.parentPage.rootElement.querySelector("#projects-tree"));

		// HTML
		let html = `<div class="filter"><input type="text" placeholder="` + this.app.interfaceLanguage.text("application.aside.tree.filter.input_placeholder") + `" /><div>x</div></div>
<ul class="list hidden">list</ul>
<ul class="tree"></ul>`;
		this.rootElement.innerHTML = html;

		// DOM ELEMENTS
		this.filter_input = this.rootElement.querySelector(".filter input");
		let filter_clear: HTMLInputElement = this.rootElement.querySelector(".filter div");
		this.ulList = this.rootElement.querySelector("ul.list");
		this.ulTree = this.rootElement.querySelector("ul.tree");

		// EVENTS
		this.filter_input.addEventListener("keydown", (e: KeyboardEvent) => {
			if (e.which === 27) {
				this.filter_input.value = "";
				this.updateFilter;
			}
		});
		this.filter_input.addEventListener("keyup", (e: KeyboardEvent) => {
			this.updateFilter();
		});
		filter_clear.addEventListener("click", () => {
			this.filter_input.value = "";
			this.updateFilter();
		});

		// BUTTON UPDATE
		this.buttonProjectsUpdate = this.parentPage.rootElement.querySelector("#projects-update");
		this.buttonProjectsUpdate.addEventListener("click", () => {
			this.loadProjectsStructure();
		});

		// LOAD
		this.loadProjectsStructure();
	}

	public loadProjectsStructure(): void {
		// CHECK IF USER LOGGED
		if (!this.app.userData.isLoggedIn) {
			console.warn("user not logged, projects tree not loaded");
			return;
		}

		//
		this.stopTimeout();

		//
		this.loadingHidden = false;
		new Fetcher(this.app.apiURL + "?action=projects-structure",
			(values: any) => {
				this.loadingHidden = true;
				this.data = <any[]>values;
				this.updateTree();
				this.updateTimeout();
			},
			(httpStatus: number, values: any) => {
				this.loadingHidden = true;
				this.updateTimeout();
			}
		).send();
	}

	private updateTree(): void {
		// ROOT UL
		//let rootUL: HTMLElement = this.rootElement.querySelector("ul.tree");

		// MARK ALL ITEMS AS OLD
		let currentItems = this.ulTree.querySelectorAll("li");
		for (let i: number = 0; i < currentItems.length; i++) {
			currentItems[i].dataset.old = "1";
		}

		// CYCLE PROJECTS
		if (this.data.projects != null) {
			for (let p = 0; p < this.data.projects.length; p++) {
				let cursor_project: any = this.data.projects[p];
				let project: HTMLElement = this.ulTree.querySelector("*[data-idnode=\"" + cursor_project.i + "\"][data-type=\"project\"]");
				if (project === null) {
					// RENDER
					project = this.renderProjectLI(cursor_project, true);
					this.ulTree.appendChild(project);
					// OPEN/CLOSED
					if (this.data.projects.length === 1) {
						project.classList.add("open");
						project.classList.remove("closed");
					}
					else {
						project.classList.remove("open");
						project.classList.add("closed");
					}
				}
				else {
					project.removeAttribute("data-old");
					(<HTMLElement>project.querySelector("span.name")).innerText = cursor_project.n;	// update name
					(<HTMLElement>project.querySelector("span.led")).style.backgroundColor = "#" + Utilities.getStatusColor(cursor_project.s);	// update status
				}
				//
				let projectUl = project.querySelector("ul");

				// CYCLE THRU SECTIONS
				if (cursor_project.sections != null) {
					for (let s = 0; s < cursor_project.sections.length; s++) {
						let cursor_section: any = cursor_project.sections[s];
						let section: HTMLElement = projectUl.querySelector("*[data-idnode=\"" + cursor_section.i + "\"][data-type=\"section\"]");
						if (section === null) {
							// RENDER
							section = this.renderSectionLI(cursor_section, true);
							projectUl.appendChild(section);
						}
						else {
							section.removeAttribute("data-old");
							(<HTMLElement>section.querySelector("span.name")).innerText = cursor_section.n;		// update name
							(<HTMLElement>section.querySelector("span.led")).style.backgroundColor = "#" + Utilities.getStatusColor(cursor_section.s);	// update status
						}
						//
						let sectionUl = section.querySelector("ul");

						// CYCLE THRU ELEMENTS
						if (cursor_section.elements != null) {
							for (let s = 0; s < cursor_section.elements.length; s++) {
								let cursor_element: any = cursor_section.elements[s];
								let element: HTMLElement = sectionUl.querySelector("*[data-idnode=\"" + cursor_element.i + "\"][data-type=\"element\"]");
								if (element === null) {
									// RENDER
									element = this.renderElementLI(cursor_element);
									sectionUl.appendChild(element);
								}
								else {
									element.removeAttribute("data-old");
									(<HTMLElement>element.querySelector("span.name")).innerText = cursor_element.n;		// update name
									(<HTMLElement>element.querySelector("span.led")).style.backgroundColor = "#" + Utilities.getStatusColor(cursor_element.s);	// update status
								}
							}
						}
					}
				}
			}
		}

		//
		let itemsToRemove = this.ulTree.querySelectorAll("li[data-old=\"1\"]");
		if (itemsToRemove.length != 0) {
			itemsToRemove.forEach((e, i) => {
				e.parentElement.removeChild(e);
			});
		}
	}

	private updateFilter(): void {
		//console.log("updateFilter: ", this.filter_input.value);

		// CLEANUP LIST
		this.ulList.innerHTML = "";

		//
		if (this.filter_input.value === "") {
			// TREE
			this.ulList.classList.add("hidden");
			this.ulTree.classList.remove("hidden");
		}
		else {
			// LIST
			this.ulList.classList.remove("hidden");
			this.ulTree.classList.add("hidden");

			// SEARCH PROJECTS
			let foundItems: number = 0;
			let searchText: string = this.filter_input.value.toLowerCase();
			if (this.data.projects != null) {
				for (let p = 0; p < this.data.projects.length; p++) {
					let cursor_project: any = this.data.projects[p];
					if (cursor_project.n.toLowerCase().indexOf(searchText) != -1) {
						this.ulList.appendChild(this.renderProjectLI(cursor_project, false));
						foundItems++;
					}

					// SEARCH SECTIONS
					if (cursor_project.sections != null) {
						for (let s = 0; s < cursor_project.sections.length; s++) {
							let cursor_section: any = cursor_project.sections[s];
							if (cursor_section.n.toLowerCase().indexOf(searchText) != -1) {
								this.ulList.appendChild(this.renderSectionLI(cursor_section, false));
								foundItems++;
							}

							// SEARCH ELEMENTS
							if (cursor_section.elements != null) {
								for (let s = 0; s < cursor_section.elements.length; s++) {
									let cursor_element: any = cursor_section.elements[s];
									if (cursor_element.n.toLowerCase().indexOf(searchText) != -1) {
										this.ulList.appendChild(this.renderElementLI(cursor_element));
										foundItems++;
									}
								}
							}
						}
					}
				}
			}

			// NO ITEMS FOUND
			if (foundItems === 0) {
				let noItemsFound: HTMLElement = document.createElement("li");
				this.ulList.appendChild(noItemsFound);
				noItemsFound.classList.add("no-items-found");
				noItemsFound.innerText = this.app.interfaceLanguage.text("application.aside.tree.filter.no_items_found");
			}
		}
	}

	private stopTimeout(): void {
		window.clearTimeout(this.autoUpdateTimeout);
	}

	private updateTimeout(): void {
		this.stopTimeout();
		this.autoUpdateTimeout = window.setTimeout(() => { this.loadProjectsStructure() }, this.autoUpdateDelay);
	}

	//
	private renderProjectLI(projectData: any, showArrow?: boolean): HTMLElement {
		//
		showArrow = (showArrow === undefined) ? true : showArrow;

		//
		let project: HTMLLIElement = document.createElement("li");
		project.dataset.idnode = projectData.i;
		project.dataset.type = "project";
		project.classList.add("project");

		// SPAN
		let span = document.createElement("span");
		project.appendChild(span);

		// ARROW (COLLAPSED)
		if (showArrow) {
			let arrow = document.createElement("span");
			span.appendChild(arrow);
			arrow.classList.add("arrow");
			arrow.addEventListener("click", (e: Event) => {
				e.preventDefault();
				e.stopPropagation();
				//
				project.classList.toggle("closed");
				project.classList.toggle("open");
			});
		}

		// LED
		span.appendChild(Utilities.getStatusLed(projectData.s));

		// NAME
		let span_name = document.createElement("span");
		span.appendChild(span_name);
		span_name.classList.add("name");
		span_name.innerText = projectData.n;

		// UL
		let ul = document.createElement("ul");
		project.appendChild(ul);
		//
		span.addEventListener("click", () => {
			new ProjectPage(this.app, projectData.i);
		});

		//
		return project;
	}

	private renderSectionLI(sectionData: any, showArrow?: boolean): HTMLLIElement {
		//
		showArrow = (showArrow === undefined) ? true : showArrow;

		//
		let section: HTMLLIElement = document.createElement("li");
		section.dataset.idnode = sectionData.i;
		section.dataset.type = "section";
		section.classList.add("section");
		section.classList.add("closed");

		// SPAN
		let span = document.createElement("span");
		section.appendChild(span);

		// ARROW (COLLAPSED)
		if (showArrow) {
			let arrow = document.createElement("span");
			span.appendChild(arrow);
			arrow.classList.add("arrow");
			arrow.addEventListener("click", (e: Event) => {
				e.preventDefault();
				e.stopPropagation();
				//
				section.classList.toggle("closed");
				section.classList.toggle("open");
			});
		}

		// LED
		span.appendChild(Utilities.getStatusLed(sectionData.s));

		// NAME
		let span_name = document.createElement("span");
		span.appendChild(span_name);
		span_name.classList.add("name");
		span_name.innerText = sectionData.n;

		// UL
		let ul = document.createElement("ul");
		section.appendChild(ul);
		//
		span.addEventListener("click", () => {
			new SectionPage(this.app, sectionData.i);
		});

		//
		return section;
	}

	private renderElementLI(elementData: any): HTMLLIElement {
		//
		let element: HTMLLIElement = document.createElement("li");
		element.dataset.idnode = elementData.i;
		element.dataset.type = "element";
		element.classList.add("element");

		// SPAN
		let span = document.createElement("span");
		element.appendChild(span);

		// LED
		span.appendChild(Utilities.getStatusLed(elementData.s));

		// NAME
		let span_name = document.createElement("span");
		span.appendChild(span_name);
		span_name.classList.add("name");
		span_name.innerText = elementData.n;
		//
		span.addEventListener("click", () => {
			new ElementPage(this.app, elementData.i);
		});

		//
		return element;
	}
}