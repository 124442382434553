import { App } from "../../app.module";
import { BasePage } from "../../baseclasses/BasePage";
import { FullPage } from "../../Pages/FullPage/FullPage";

export class ProjectPage extends BasePage {
	constructor(public app: App, public IDproject: number) {
		super(app);
		this.renderPage();
	}

	public renderPage(): void {
		//
		this.parentPage = new FullPage(this.app);

		//
		this.rootElement = (<HTMLElement>this.parentPage.rootElement.querySelector("main"));
		this.rootElement.innerHTML = "<h2>Project " + this.IDproject.toString() + "</h2><i>Not developed yet.</i>";
	}
}