import { BasePage } from "../../baseclasses/BasePage";
import { App } from "../../app.module";
import { FullPage } from "../FullPage/FullPage";
import { Fetcher } from "../../Utilities/Fetcher";

export class UserProfilePage extends BasePage {
    constructor(public app: App) {
        super(app);
        this.render();
    }

    render(): void {
        //
        this.parentPage = new FullPage(this.app);

        // DOM ELEMENTS
        this.rootElement = (<HTMLElement>this.parentPage.rootElement.querySelector("main"));

        // CREATE
        let html = `<div class="userprofilepage">
	<h2>` + this.app.interfaceLanguage.text("application.userprofile.name") + `</h2>
	<div>
		<div class="field">
			<label>` + this.app.interfaceLanguage.text("application.userprofile.update_password.password_label") + `</label>
			<input type="password" name="password" maxlength="50" />
		</div>
		<div class="field">
			<label>` + this.app.interfaceLanguage.text("application.userprofile.update_password.rewrite_password_label") + `</label>
			<input type="password" name="password_rewritten" maxlength="50" />
		</div>
		<div class="button">` + this.app.interfaceLanguage.text("application.userprofile.update_password.button_text") + `</div>
		<ul class="messages"></ul>
	</div>
</div>`;
        this.rootElement.innerHTML = html;

        // DOM ELEMENTS
        let buttonSubmit: HTMLElement = this.rootElement.querySelector(".button");
        let inputs: NodeListOf<HTMLInputElement> = <NodeListOf<HTMLInputElement>>this.rootElement.querySelectorAll(".field input");

        // EVENTS
        buttonSubmit.addEventListener("click", () => {
            this.submitForm();
        });
        inputs.forEach(input => {
            input.addEventListener("keydown", (e: KeyboardEvent) => {
                // ENTER
                if (e.which == 13) this.submitForm();
                // ESC
                if (e.which == 27) this.resetForm();
            });
        });
    }

    private resetForm(): void {
        let inputs: NodeListOf<HTMLInputElement> = <NodeListOf<HTMLInputElement>>this.rootElement.querySelectorAll(".field input");
        inputs.forEach(input => {
            input.value = "";
        });
        if (inputs.length !== 0) inputs[0].focus();
    }

    private submitForm(): void {
        //
        let messages: HTMLElement = this.rootElement.querySelector(".messages");
        messages.innerHTML = "";

        // VALUES
        const p: string = (<HTMLInputElement>this.rootElement.querySelector("input[name=\"password\"]")).value;
        const p_r: string = (<HTMLInputElement>this.rootElement.querySelector("input[name=\"password_rewritten\"]")).value;

        // VALIDATE
        let errors: string[] = [];
        if (p.length < 8) errors.push(this.app.interfaceLanguage.text("application.userprofile.update_password.messages.password_too_short"));
        if (p !== p_r) errors.push(this.app.interfaceLanguage.text("application.userprofile.update_password.messages.password_differs"));
        if (errors.length !== 0) {
            //
            errors.forEach(error => {
                let li: HTMLLIElement = document.createElement("li");
                messages.appendChild(li);
                li.innerText = error;
            });
            //
            return;
        }

        // SUBMIT
        new Fetcher(this.app.apiURL + "?action=userprofile-update-p&p=" + encodeURIComponent(p),
            (values: any) => {
                messages.innerText = this.app.interfaceLanguage.text("application.userprofile.update_password.messages.success");
            },
            (httpStatus: number, values: any) => {
                let message: string = (values !== undefined && values.message !== undefined) ? values.message : this.app.interfaceLanguage.text("application.userprofile.update_password.messages.error");
                messages.innerText = message;
            }
        ).send();
    }
}