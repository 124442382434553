import { App } from "../app.module";

export abstract class BasePage {
    //
    public parentPage: BasePage;
    public rootElement: HTMLElement;

	//
    constructor(public app: App) {
        //
    }
}