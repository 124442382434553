import { App } from "../../app.module";
import { BasePage } from "../../baseclasses/BasePage";

export class LoginPage extends BasePage {
	// DOM ELEMENTS
	private button: HTMLButtonElement;
	private buttonInitialWidth: number;
	//
	private response: HTMLElement;

	//
    constructor(public app: App) {
        super(app);
        this.renderPage();
    }

	renderPage(): void {
		// CREATE
        let html = `<div id=\"login\">
	<div class=\"inner\">
		<div class="box">
			<h1 class="title">` + this.app.appName + `</i></h1>
			<input type="text" id="login_username" placeholder="` + this.app.interfaceLanguage.text("application.login.ph_username") + `" />
			<input type="password" id="login_password" placeholder="` + this.app.interfaceLanguage.text("application.login.ph_password") + `" />
			<button id="login_button" class="button">` + this.app.interfaceLanguage.text("application.login.button_text") + `</button>
			<div class="response"></div>
			<hr>
			<div class="interface-languages-label">` + this.app.interfaceLanguage.text("application.login.languages") + `</div>
			<ul class="interface-languages"></ul>
		</div>
		<div class="kilopix">powered by <a href="http://kilopix.ch" target="_blank">Kilopix</a></div>
	</div>
</div>`;
		this.app.rootElement.innerHTML = html;

		// DOM ELEMENTS
		this.response = this.app.rootElement.querySelector(".response");

        // INPUT EVENTS
        let inputs: NodeListOf<HTMLInputElement> = <NodeListOf<HTMLInputElement>>this.app.rootElement.querySelectorAll("input");
		for (let i: number = 0; i < inputs.length; i++)
		{
			inputs[i].addEventListener("keypress", (e: KeyboardEvent) => {
				// RESET MESSAGE
				this.responseMessage = "";
				// ENTER
				if (e.which == 13) this.login();
			});
		}

		// BUTTON EVENTS
        this.button = this.app.rootElement.querySelector("button#login_button");
        this.button.addEventListener("click", (e: MouseEvent) => {
            e.preventDefault();
            //
            this.login();
		});
		this.buttonInitialWidth = this.button.getBoundingClientRect().width;

        // INTERFACE-LANGUAGES
        let interfacelanguages: HTMLUListElement = this.app.rootElement.querySelector("ul.interface-languages");
        this.app.environment.interfaceLanguages.forEach(languageCode => {
            let li = document.createElement("li");
			interfacelanguages.appendChild(li)
			li.classList.add("button");
			if (languageCode == this.app.settings.interfaceLanguage)
				li.classList.add("selected");
			li.dataset.language = languageCode;
			li.innerText = languageCode;
			if (languageCode != this.app.settings.interfaceLanguage) {
				li.addEventListener("click", () => {
					if (languageCode != this.app.settings.interfaceLanguage) {
						this.app.preloaderHidden = false;
						this.app.interfaceLanguage.loadLanguage(languageCode);
					}
				});
			}
        });
    }

    login(): void {
        //
        let userName: string = (<HTMLInputElement>this.app.rootElement.querySelector("input#login_username")).value;
        let password: string = (<HTMLInputElement>this.app.rootElement.querySelector("input#login_password")).value;
		//
		this.loading = true;
		this.app.userData.login(userName, password, (message: string) => {
			this.loading = false;
			this.responseMessage = message;
		});
	}

	private set loading(visible: boolean) {
		//
		this.button.classList.toggle("loading", visible);
		//
		if (this.button.classList.contains("loading"))
			this.button.style.width = (this.buttonInitialWidth + 25).toString() + "px";
		else
			this.button.style.width = this.buttonInitialWidth.toString() + "px";
	}

	private set responseMessage(message: string) {
		this.response.innerText = message;
	}
}