import { App } from "../app.module";
import { LoginPage } from "../Pages/LoginPage/LoginPage";
import { DashboardPage } from "../Pages/DashboardPage/DashboardPage";
import { Fetcher } from "../Utilities/Fetcher";

export class UserData {
    //
    initialized: boolean = false;
    keepAliveTimeout: number = 0;
    keepAliveDelay: number = 30000;

    //
    isLoggedIn: boolean = false;
    IDuser: number = 0;
    name: string = null;
    companyName: string = null;

    //
    constructor(public app: App) {
        //
    }

    initialize(): void {

		this.loadUserData(() => {
			// SUCCESS
			this.initialized = true;
			this.app.initializeLoop();

			// START KEEPALIVE
			this.keepAliveStart();
		});
    }

	private keepAliveStart(): void {
		//
		if (!this.isLoggedIn) return;
        //
        this.keepAliveStop();
		//
		this.keepAlive();
    }

    private keepAliveStop(): void {
        window.clearTimeout(this.keepAliveTimeout);
	}

	public keepAlive(): void {
		new Fetcher(this.app.apiURL + "?action=keepalive&language=" + this.app.settings.interfaceLanguage,
			(values: any) => {
				window.setTimeout(() => { this.keepAliveStart() }, this.keepAliveDelay);
			},
			(httpStatus: number, values: any) => {
				console.warn("keepAlive failed (httpStatus=" + httpStatus.toString() + ")");
				this.resetData();
				this.keepAliveStop();
				new LoginPage(this.app);
			}
		).send();
	}

	public loadUserData(callback?:Function): void {
		new Fetcher(this.app.apiURL + "?action=userdata",
			(values: any) => {
				this.parseUserData(values);
				this.isLoggedIn = true;

				// CALLBACK
				if (callback !== undefined) callback();
			},
			(httpStatus: number, values: any) => {
				this.isLoggedIn = false;
				this.resetData();

				// CALLBACK
				if (callback !== undefined) callback();
			}
		).send();
	}

    private parseUserData(valuesJSON: any): void {
		//
		this.IDuser = valuesJSON.data.IDuser;
		this.name = valuesJSON.data.name;
		this.companyName = valuesJSON.data.companyName;
    }

	public login(userName: string, password: string, callback?: Function): void {
		new Fetcher(this.app.apiURL + "?action=login&userName=" + encodeURIComponent(userName) + "&p=" + encodeURIComponent(password) + "&language=" + this.app.settings.interfaceLanguage,
			(values: any) => {
				this.parseUserData(values);
				this.isLoggedIn = true;
				if (callback !== undefined) callback(values.message);
				this.keepAliveStart();
				new DashboardPage(this.app);
			},
			(httpStatus: number, values: any) => {
				let message: string = "An error occurred.";	// TODO: gestire messaggio da InterfaceLanguage
				if (values !== undefined && values.message != undefined) message = values.message;
				if (callback !== undefined) callback(message);
			}
		).send();
    }

	public logout(): void {
		new Fetcher(this.app.apiURL + "?action=logout",
			(values: any) => {
				this.resetData();
				this.keepAliveStop();
				new LoginPage(this.app);
			},
			(httpStatus: number, values: any) => {
				// TODO: gestire messaggio da InterfaceLanguage
				alert("An error occurred.");
			}
		).send();
	}

	private resetData(): void {
		this.isLoggedIn = false;
		this.IDuser = 0;
		this.name = null;
		this.companyName = null;
	}
}