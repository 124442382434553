import { App } from "../app.module";

export class Utilities {
	constructor() { }

	public static getStatusLed(status?: string): HTMLElement {
		//
		let led = document.createElement("span");
		led.classList.add("led");
		led.style.backgroundColor = "#" + Utilities.getStatusColor(status);;
		return led;
	}

	public static getStatusColor(status?: string): string {
		if (status === undefined) return "000000";
		else return status;
	}

	public static fileLengthToString(fileLength: number): string {
		if (fileLength < 1024) return Utilities.round(fileLength, 1).toString() + " bytes";
		else {
			let kiloBytes = Math.ceil(fileLength / 1024);
			if (kiloBytes < 1024) return Utilities.round(kiloBytes, 1).toString() + " kb";
			else {
				let megaBytes: number = (kiloBytes / 1024);
				if (megaBytes < 1024) return Utilities.round(megaBytes, 1).toString() + " Mb";
				else {
					let gigaBytes: number = (megaBytes / 1024);
					return Utilities.round(gigaBytes, 1).toString() + " Gb";
				}
			}
		}
	}

	public static round(number:number, decimalPlaces:number): number {
		let p: number = Math.pow(10, decimalPlaces);
		return Math.round(number * p) / p;
	}

	public static openFile(apiURL: string, IDnode: number, fieldName?: string, ranking?: number): void {
		let fileURL: string = apiURL + "?action=download&IDnode=" + IDnode.toString();
		if (fieldName !== undefined) fileURL += "&field=" + fieldName;
		if (ranking !== undefined) fileURL += "&rank=" + ranking.toString();
		window.open(fileURL);
	}

	public static getTypeByID(app: App, IDtype: number): any {
		let foundType: any = null;
		for (let prop in app.environment.types) {
			if (app.environment.types.hasOwnProperty(prop)) {
				if (app.environment.types[prop].IDtype === IDtype)
					foundType = app.environment.types[prop];
			}
		}
		return foundType;
	}

	public static timeStampToDate(timestamp: number): Date {
		let offset = new Date().getTimezoneOffset();
		return new Date((timestamp - (offset * 60)) * 1000);
	}

	public static formatDateLanguage(date: Date, languageCode: string): string {
		let format: string = null;
		switch (languageCode) {
			case "IT": return Utilities.formatDate(date, "dd/MM/yyyy");
			default: return Utilities.formatDate(date, "MM/dd/yyyy");	// DEFALT IS ENGLISH
		}
	}

	public static formatDate(date: Date, format: string): string {
		switch (format) {
			case "dd/MM/yyyy":
				return date.getDate()
					+ "/"
					+ (date.getMonth() + 1)
					+ "/"
					+ date.getFullYear();
			// DEFAULT IS ENGLISH FORMAT
			default: case "MM/dd/yyyy":
				return (date.getMonth() + 1)
					+ "/"
					+ date.getDate()
					+ "/"
					+ date.getFullYear();
		}
	}
}