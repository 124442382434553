import { App } from "../app.module";
import { Fetcher } from "../Utilities/Fetcher";

export class InterfaceLanguage {
    //
    loading: boolean = false;
    initialized: boolean = false;
	//
    data: any;

	//
    constructor(public app: App) {
		//
    }

	initialize(): void {
		// CHECK ENVIRONMENT LOADED
        if (!this.app.environment.initialize) {
            this.app.environment.initialize();
            return;
        }

        // CHECK LOCALSTORAGE
        let languageToLoad: string = this.app.environment.interfaceLanguages[0]; // default: first language in array
        if (this.app.settings.interfaceLanguage != null
            && this.app.environment.interfaceLanguages.indexOf(this.app.settings.interfaceLanguage) != -1)
            languageToLoad = this.app.settings.interfaceLanguage;

        // LOAD
        this.loadLanguage(languageToLoad);
    }

    public loadLanguage(languageCode: string) {
		//
		if (this.loading) return;
		this.loading = true;

		//
		new Fetcher(this.app.apiURL + "?action=interfacelanguage&language=" + languageCode,
			(values: any) => {
				// SUCCESS
				this.loading = false;
				this.initialized = true;
				this.app.settings.interfaceLanguage = languageCode;
				this.data = values;

				// CALLBACK
				this.app.initializeLoop();
			},
			(httpStatus: number, values: any) => {
				console.warn("error loading interface language");
			}
		).send();
	}

	public node(path: string): any {
		//
		if (path === undefined || path === null) return undefined;
		if (this.data === null || this.data === undefined) return undefined;
		//
		let parts: string[] = path.split(".");
		var cursor: any = this.data;
		for (let i: number = 0; i < parts.length; i++) {
			cursor = <any>cursor[parts[i]];
			if (cursor === undefined) return undefined;
		}
		//
		return cursor;
	}

	public text(path: string): string {
		let node: any = this.node(path);
		if (node === undefined) return "[" + path + "]";
		return <string>node;
	}
}