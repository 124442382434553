export class TimeOffset {
	//
	private timeout: number;
	public delay: number = 30000;	// milliseconds

	constructor() {
		//
		this.start();
	}

	start(): void {
		//
		this.stop();
		//
		this.updateAll();
		//
		this.timeout = window.setTimeout(() => { this.start() }, this.delay);
	}

	stop(): void {
		window.clearTimeout(this.timeout);
	}

	updateAll(): void {
		//
		let now: number = TimeOffset.nowAsTimeStamp();
		let elements: NodeListOf<HTMLElement> = <NodeListOf<HTMLElement>>document.querySelectorAll("*[data-timeoffset]");
		for (let i: number = 0; i < elements.length; i++)
			TimeOffset.update(elements[i], now);
	}

	public static update(element: HTMLElement, now?: number): void {
		//
		if (now === undefined) now = Math.round(new Date().getTime() / 1000);
		//
		let timestamp: number = parseInt(element.dataset.timeoffset);
		let offset: string = this.offsetToString(timestamp, now);
		element.innerText = offset;
	}

	public static createElement(timestamp: number, tagName?: string): HTMLElement {
		if (tagName === undefined) tagName = "span";
		let element: HTMLElement = document.createElement(tagName);
		element.dataset.timeoffset = timestamp.toString();
		element.innerText = TimeOffset.offsetToString(timestamp);
		return element;
	}

	public static offsetToString(timestamp: number, now?: number): string {
		//
		if (now === undefined) now = Math.round(new Date().getTime() / 1000);
		//
		let offsetSeconds: number = now - timestamp;
		if (offsetSeconds < 60) {
			return "just now";
		} else if (offsetSeconds < (60 * 60))
			return Math.round(offsetSeconds / 60).toString() + " m";
		else if (offsetSeconds < 60 * 60 * 24) {
			return Math.round(offsetSeconds / 60 / 60).toString() + " h";
		} else if (offsetSeconds < 60 * 60 * 24 * 7) {
			return Math.round(offsetSeconds / 60 / 60 / 24).toString() + " d";
		} else if (offsetSeconds < 60 * 60 * 24 * 7 * 4)
			return Math.round(offsetSeconds / 60 / 60 / 24 / 7).toString() + " w";
		else if (offsetSeconds < 60 * 60 * 24 * 7 * 4 * 12)
			return Math.round(offsetSeconds / 60 / 60 / 24 / 7 / 4).toString() + " mo";
		else
			return Math.round(offsetSeconds / 60 / 60 / 24 / 7 / 4 / 12).toString() + " y";
	}

	public static nowAsTimeStamp(): number {
		return Math.round(new Date().getTime() / 1000);
	}
}