import { Utilities } from "../../Utilities/Utilities";
import { App } from "../../app.module";
import { Fetcher } from "../../Utilities/Fetcher";
import { TimeOffset } from "../../modules/TimeOffset";

export class Thread {
	//
	private isSendingData: boolean = false;

	// DOM ELEMENTS
	buttonNewThread: HTMLElement;
	formNewThread: HTMLElement;
	buttonAddFile: HTMLElement;
	buttonRemoveFile: HTMLElement;
	buttonCancel: HTMLElement;
	buttonSubmit: HTMLElement;
	//
	inputText: HTMLInputElement;
	textareaText: HTMLInputElement;
	inputFileUpload: HTMLInputElement;
	spanFileName: HTMLElement;

	//
	constructor(private app: App, private domElement: HTMLElement, private IDthread: number) {
		this.loadData();
	}

	loadData(): void {
		new Fetcher(this.app.apiURL + "?action=thread&IDthread=" + this.IDthread.toString(),
			(values: any) => {
				this.domElement.classList.remove("loading");
				this.renderData(values.data);
			},
			(httpStatus: number, values: any) => {
				this.domElement.classList.remove("loading");
				console.warn("error loading Element data");
			}
		).send();
	}

	private renderData(data: any): void {
		// CLEANUP
		while (this.domElement.hasChildNodes()) this.domElement.removeChild(this.domElement.firstChild);

		// FORMS
		if (data.forms === undefined) {
			//console.log("no forms");
		}
		else {
			if (data.forms != null) {
				for (let i: number = 0; i < data.forms.length; i++) {
					let formConfig: any = data.forms[i];
					this.domElement.appendChild(this.renderForm(formConfig));
				}
			}
		}

		// CYCLE
		for (let i: number = 0; i < data.comments.length; i++) {
			//
			let item: any = data.comments[i];
			let statusColor: string = Utilities.getStatusColor(item.s);
			let dateCreated: Date = Utilities.timeStampToDate(item.tc);
			let dateCreatedString: string = Utilities.formatDateLanguage(dateCreated, this.app.settings.interfaceLanguage);
			let timeoffset: HTMLElement = TimeOffset.createElement(item.tc, "span");
			timeoffset.classList.add("timeoffset");
			//
			let html = `<div class="led" style="background-color:#` + statusColor + `"></div>
<div class="info">
	<div class="author">` + item.an + `</div>
	<div class="date">${dateCreatedString} ${timeoffset.outerHTML}</div>
</div>
<div class="text"></div>`;
			let itemDOM: HTMLElement = document.createElement("div");
			this.domElement.appendChild(itemDOM);
			itemDOM.classList.add("comment");
			itemDOM.innerHTML = html;

			// TEXT PLACEHOLDER
			let textContainer: HTMLElement = itemDOM.querySelector(".text");

			// TITLE
			if (item.n !== undefined) {
				let title: HTMLElement = document.createElement("div");
				textContainer.appendChild(title);
				title.classList.add("name");
				title.innerText = item.n;
			}

			// DESCRIPTION
			if (item.d !== undefined) {
				let description: HTMLElement = document.createElement("div");
				textContainer.appendChild(description);
				description.classList.add("description");
				description.innerText = item.d;
			}

			// FILE
			if (item.fl !== undefined && item.fe !== undefined) {
				//
				let fileContainer: HTMLElement = document.createElement("div");
				itemDOM.appendChild(fileContainer);
				fileContainer.classList.add("file-container");
				//
				let fileButton: HTMLElement = document.createElement("div");
				fileContainer.appendChild(fileButton);
				fileButton.classList.add("button");
				fileButton.innerHTML = this.app.interfaceLanguage.text("application.thread.item.file_link_text") + " <span>(" + item.fe.toUpperCase() + " - " + Utilities.fileLengthToString(item.fl) + ")</span>";
				fileButton.addEventListener("click", () => {
					Utilities.openFile(this.app.apiURL, item.i);
				});
			}
		}
	}

	//private resetForm(formConfig:any): void {
	//	this.inputText.value = "";
	//	this.textareaText.value = "";
	//	this.inputFileUpload.value = "";
	//	this.spanFileName.innerText = "";
	//	this.buttonRemoveFile.classList.add("hidden");
	//}

	private submitForm(formConfig: any, sender: HTMLButtonElement): void {
		//console.log("submitForm", formConfig, sender);

		//
		if (this.isSendingData === true) return;

		// ERRORS
		let errors: string[] = [];

		// DESCRIPTION
		let description: string = null;
		if (formConfig.description !== undefined && formConfig.description.show === true) {
			description = formConfig.domElement.querySelector(".description textarea").value;
			if (formConfig.description.mandatory !== undefined && formConfig.description.mandatory === true && description.replace(/ /g, "").length == 0)
				errors.push("[description is a mandatory field]");
		}

		// FILE
		let file: File = null;
		if (formConfig.file !== undefined && formConfig.file.show === true) {
			file = formConfig.domElement.querySelector(".file input[type=\"file\"]").files[0]
			if (formConfig.file.mandatory !== undefined && formConfig.file.mandatory === true && file === null)
				errors.push("[file is a mandatory field]");
		}

		// RECEIVER
		let receiver: number = 0;
		if (formConfig.operators !== undefined && formConfig.operators.length !== 0) {
			let select: HTMLSelectElement = formConfig.domElement.querySelector(".buttons .col.right select");
			if (select !== null)
				receiver = parseInt(select.options[select.selectedIndex].value);
		}

		// TARGET STATUS
		let targetStatus: number = parseInt(sender.dataset.targetstatus);


		//// SOME LOGGING
		//console.log("description", description);
		//console.log("file", file);
		//console.log("targetStatus", targetStatus);
		//console.log("receiver", receiver);


		// SEND
		let fetcher:Fetcher = new Fetcher(this.app.apiURL + "?action=thread-add-comment",
			(values: any) => {
				//
				this.isSendingData = false;
				this.loadData();
			},
			(httpStatus: number, values: any) => {
				this.isSendingData = false;
				// TODO: migliorare messaggi utente
				alert("error loading data");
			}
		)
			.append("IDthread", this.IDthread.toString())
			.append("description", description)
			.append("file", file)
			.append("targetStatus", targetStatus.toString());
		if (receiver != null && receiver !== 0) fetcher.append("IDreceiver", receiver.toString());
		fetcher.send();
		//
		this.isSendingData = true;
	}

	private renderForm(formConfig: any): HTMLElement {
		//
		let form: HTMLElement = document.createElement("div");
		formConfig.domElement = form;
		form.classList.add("form-standard");

		// TITLE
		let title: HTMLElement = document.createElement("div");
		form.appendChild(title);
		title.classList.add("title");
		title.innerText = formConfig.title;

		// FORM BODY
		let formBody: HTMLElement = document.createElement("div");
		form.appendChild(formBody);
		// OPEN
		if (formConfig.open === undefined || formConfig.open === false)
			formBody.classList.add("hidden");

		// EVENT > TITLE
		title.addEventListener("click", () => {
			formBody.classList.toggle("hidden");
		});

		// NOTES
		if (formConfig.notes !== undefined) {
			let notes: HTMLElement = document.createElement("div");
			formBody.appendChild(notes);
			notes.classList.add("notes");
			notes.innerText = formConfig.notes;
		}

		// DESCRIPTION
		if (formConfig.description !== undefined && formConfig.description.show === true) {
			//
			let descriptionContainer: HTMLElement = document.createElement("div");
			formBody.appendChild(descriptionContainer);
			descriptionContainer.classList.add("description");
			//
			let description: HTMLTextAreaElement = <HTMLTextAreaElement>document.createElement("textarea");
			descriptionContainer.appendChild(description);
			description.placeholder = "Description";
			description.rows = 3;
		}

		// FILE
		if (formConfig.file !== undefined && formConfig.file.show === true) {
			let fileContainer: HTMLElement = document.createElement("div");
			formBody.appendChild(fileContainer);
			fileContainer.classList.add("file");

			//
			let fileHtml: string = `<button class="button addfile">` + this.app.interfaceLanguage.text("application.threads.startnew.button_addfile_text") + `</button>
<span class="filename"></span>
<button class="removefile hidden"></button>
<input type="file" class="input-file" />`;
			fileContainer.innerHTML = fileHtml;

			// DOM ELEMENTS
			let buttonAddFile = fileContainer.querySelector(".button.addfile");
			let inputFileUpload = <HTMLInputElement>fileContainer.querySelector("input[type=\"file\"]");
			let spanFileName = <HTMLElement>fileContainer.querySelector("span.filename");
			let buttonRemoveFile = fileContainer.querySelector(".removefile");

			// EVENTS
			// BUTTON ADDFILE
			buttonAddFile.addEventListener("click", () => {
				inputFileUpload.click();
			});

			// BUTTON REMOVEFILE
			buttonRemoveFile.addEventListener("click", () => {
				inputFileUpload.value = "";
				spanFileName.innerText = "";
				buttonRemoveFile.classList.add("hidden");
			});

			// FILE
			inputFileUpload.addEventListener("change", () => {
				// CLEANUP
				buttonRemoveFile.classList.add("hidden");
				spanFileName.innerText = "";

				//
				let filename: string = "";
				if (inputFileUpload.files.length != 0) {
					//
					let file: File = inputFileUpload.files[0];

					// CHECK FILE EXTENSION
					let fileExtension: String = "";
					if (file.name.indexOf(".") == -1) {
						//
						let alertText: string = this.app.interfaceLanguage.text("application.threads.startnew.messages.extension_not_allowed");
						alertText += " (";
						alertText += this.app.environment.types.ThreadElement.allowedExtensions.join(", ");
						alertText += ")";
						//
						alert(alertText);
						//
						inputFileUpload.value = "";
						return;
					}
					else {
						let fileExtension: String = file.name.substring(file.name.indexOf(".")).toLowerCase();
						if (!this.app.environment.types.ThreadElement.allowedExtensions.includes(fileExtension)) {
							//
							let alertText: string = this.app.interfaceLanguage.text("application.threads.startnew.messages.extension_not_allowed");
							alertText += " (";
							alertText += this.app.environment.types.ThreadElement.allowedExtensions.join(", ");
							alertText += ")";
							//
							alert(alertText);
							//
							inputFileUpload.value = "";
							return;
						}
					}

					// CHECK FILE MAX LENGTH
					if (file.size > this.app.environment.types.ThreadElement.fileMaxLength) {
						let alertText: string = this.app.interfaceLanguage.text("application.threads.startnew.messages.file_too_big");
						alertText += " (" + Utilities.fileLengthToString(this.app.environment.types.ThreadElement.fileMaxLength) + ")"
						//
						alert(alertText);
						//
						inputFileUpload.value = "";
						return;
					}

					//
					filename = inputFileUpload.files[0].name + " (" + Utilities.fileLengthToString(inputFileUpload.files[0].size) + ")";
				}

				//
				spanFileName.innerText = filename;
				buttonRemoveFile.classList.remove("hidden");
			});
		}

		// BUTTONS
		if (formConfig.button_left !== undefined || formConfig.button_right !== undefined) {
			//
			let buttons: HTMLElement = document.createElement("div");
			formBody.appendChild(buttons);
			buttons.classList.add("buttons");

			// BUTTON LEFT (col_left)
			let col_left: HTMLElement = document.createElement("div");
			buttons.appendChild(col_left);
			col_left.classList.add("col");
			col_left.classList.add("left");
			//
			if (formConfig.button_left !== undefined && formConfig.button_left.show === true) {
				let buttonLeft: HTMLElement = document.createElement("div");
				col_left.appendChild(buttonLeft);
				buttonLeft.classList.add("button");
				//
				buttonLeft.dataset.targetstatus = formConfig.button_left.targetStatus.toString();
				buttonLeft.style.backgroundColor = "#" + Utilities.getStatusColor(formConfig.button_left.color);
				buttonLeft.innerText = formConfig.button_left.text;
				//
				buttonLeft.addEventListener("click", (e: MouseEvent) => {
					this.submitForm(formConfig, <HTMLButtonElement>e.target);
				});
			}

			// BUTTON RIGHT
			let col_right: HTMLElement = document.createElement("div");
			buttons.appendChild(col_right);
			col_right.classList.add("col");
			col_right.classList.add("right");
			//
			if (formConfig.button_right !== undefined && formConfig.button_right.show === true) {

				// OPERATORS
				if (formConfig.operators !== undefined && formConfig.operators.length !== 0) {
					//
					let select: HTMLSelectElement = <HTMLSelectElement>document.createElement("select");
					col_right.appendChild(select);
					//
					for (let i: number = 0; i < formConfig.operators.length; i++) {
						let operator: any = formConfig.operators[i];
						let option: HTMLOptionElement = document.createElement("option");
						select.appendChild(option);
						option.value = operator.i;
						option.text = operator.n;
					}
				}

				//
				let buttonRight: HTMLElement = document.createElement("div");
				col_right.appendChild(buttonRight);
				buttonRight.classList.add("button");
				//
				buttonRight.dataset.targetstatus = formConfig.button_right.targetStatus.toString();
				buttonRight.style.backgroundColor = "#" + Utilities.getStatusColor(formConfig.button_right.color);
				buttonRight.innerText = formConfig.button_right.text;
				//
				buttonRight.addEventListener("click", (e: MouseEvent) => {
					this.submitForm(formConfig, <HTMLButtonElement>e.target);
				});
			}
		}

		// LOADING
		let loadingContainer: HTMLElement = document.createElement("div");
		formBody.appendChild(loadingContainer);
		loadingContainer.classList.add("loading-container");

		//
		return form;
	}
}