import { App } from "../app.module";
import { Fetcher } from "../Utilities/Fetcher";

export class Environment {
    //
    loading: boolean = false;
    initialized: boolean = false;
	interfaceLanguages: string[];
	types: any;

    //
    constructor(public app: App) {
        //
    }

	public initialize(): void {
		//
		if (this.loading) return;
		this.loading = true;

		//
		new Fetcher(this.app.apiURL + "?action=environment",
			(values:any) => {
				this.loadSuccess(values);
			},
			(httpStatus: number, values: any) => {
				this.loadError(values);
			}
		).send();
	}

	private loadSuccess(values: any): void {
		//
		this.initialized = true;
		this.loading = false;
		this.interfaceLanguages = <string[]>values.interfaceLanguages;
		this.types = <any>values.types;

		// CALLBACK
		this.app.initializeLoop();
	}

	private loadError(values: any): void {
		let message: string = (values != null) ? values.message : "[error]";
		alert(message);
	}
}