import { App } from "../../app.module";
import { BasePage } from "../../baseclasses/BasePage";
import { Tree } from "../../components/Tree/Tree";
import { DashboardPage } from "../DashboardPage/DashboardPage";
import { UserProfilePage } from "../UserProfilePage/UserProfilePage";

export class FullPage extends BasePage {
	//
	private tree: Tree;

	//
	constructor(public app: App) {
		super(app);
		this.renderPage();
	}

	public renderPage(): void {
		//
		this.rootElement = this.app.rootElement.querySelector("#fullpage");
		if (this.rootElement === null) {

            // CREATE
            let html = `<div id=\"fullpage\">
	<header>
		<div><h1 class="title">` + this.app.appName + `</h1> <span class="small">for <a href="http://www.albasa.ch/" target="_blank">Alba Engineering</a></span></div>
<div class="user-tools"><span class="username">` + this.app.userData.name + `</span> <div class="button button-logout">` + this.app.interfaceLanguage.text("application.user.logout_text") + `</div></div>
	</header>
	<div id="body">
		<aside class="scrollbar">
			<div class="link" id="link-dashboard">` + this.app.interfaceLanguage.text("application.dashboard.name") + `</div>
			<div class="link"><button id=\"projects-update\"></button>` + this.app.interfaceLanguage.text("application.aside.tree.projects_button") + `</div>
			<div id="projects-tree"></div>
		</aside>
		<main class="scrollbar"></main>
	</div>
</div>`;
            this.app.rootElement.innerHTML = html;
			this.rootElement = this.app.rootElement.querySelector("#fullpage");

			// HEADER EVENTS
			this.rootElement.querySelector(".button-logout").addEventListener("click", () => {
				this.app.userData.logout();
            });
            this.rootElement.querySelector(".user-tools .username").addEventListener("click", () => {
                new UserProfilePage(this.app);
            });

			// ASIDE EVENTS
			this.rootElement.querySelector("#link-dashboard").addEventListener("click", () => {
				new DashboardPage(this.app);
			});

			// TREE
			this.tree = new Tree(this.app);
			this.tree.parentPage = this;
			this.tree.initialize();
        }
    }
}