import { Utilities } from "../../Utilities/Utilities";
import { App } from "../../app.module";
import { ThreadPage } from "../../Pages/ThreadPage/ThreadPage";
import { Fetcher } from "../../Utilities/Fetcher";
import { TimeOffset } from "../../modules/TimeOffset";

export class Threads {
	//
	private isSendingData: boolean = false;

	// DOM ELEMENTS
	buttonNewThread: HTMLElement;
	formNewThread: HTMLElement;
	buttonAddFile: HTMLElement;
	buttonRemoveFile: HTMLElement;
	buttonCancel: HTMLElement;
	buttonSubmit: HTMLElement;
	//
	inputTitle:HTMLInputElement;
	inputDescription:HTMLInputElement;
	inputFileUpload:HTMLInputElement;
	spanFileName: HTMLElement;

	//
	constructor(private app: App, private domElement: HTMLElement, public IDparent: number) {
		this.loadData();
	}

	private loadData(): void {
		new Fetcher(this.app.apiURL + "?action=threads&IDparent=" + this.IDparent.toString(),
			(values: any) => {
				this.domElement.classList.remove("loading");
				this.renderData(values.data);
			},
			(httpStatus: number, values: any) => {
				this.domElement.classList.remove("loading");
				console.warn("error loading Element data");
			}
		).send();
	}

	private renderData(data: any): void {

		// CLEANUP
		while (this.domElement.hasChildNodes()) this.domElement.removeChild(this.domElement.firstChild);

		// START THREAD
		if (data.canStartThread === true) {
			//
			let startThread: HTMLElement = document.createElement("div");
			this.domElement.appendChild(startThread);
			startThread.classList.add("start-thread");
			//
			let html = `<div class="button start-thread">` + this.app.interfaceLanguage.text("application.threads.startnew.button_text") + `</div>

<div class="start-thread-form hidden">
	<input name="title" type="text" maxlength="255" placeholder="` + this.app.interfaceLanguage.text("application.threads.startnew.ph_text") + `" />
	<textarea name="text" placeholder="` + this.app.interfaceLanguage.text("application.threads.startnew.ph_description") + `" rows="5"></textarea>
	<div class="file">
		<button class="button addfile">` + this.app.interfaceLanguage.text("application.threads.startnew.button_addfile_text") + `</button>
		<span class="filename"></span>
<button class="removefile hidden"></button>
		<input type="file" class="input-file" />
	</div>
	<div class="buttons">
		<button class="button cancel">` + this.app.interfaceLanguage.text("application.threads.startnew.button_cancel_text") + `</button>
		<button class="button submit">` + this.app.interfaceLanguage.text("application.threads.startnew.button_submit_text") + `</button>
	</div>
</div>`;
			startThread.innerHTML = html;

			// DOM ELEMENTS
			this.buttonNewThread = this.domElement.querySelector(".button.start-thread");
			this.formNewThread = this.domElement.querySelector(".start-thread-form");
			this.buttonAddFile = this.domElement.querySelector(".button.addfile");
			this.buttonRemoveFile = this.domElement.querySelector(".removefile");
			this.buttonCancel = this.domElement.querySelector(".button.cancel");
			this.buttonSubmit = this.domElement.querySelector(".button.submit");
			// FORM
			this.inputTitle = <HTMLInputElement>this.domElement.querySelector("input[name=\"title\"]");
			this.inputDescription = <HTMLInputElement>this.domElement.querySelector("textarea[name=\"text\"]");
			this.inputFileUpload = <HTMLInputElement>this.domElement.querySelector("input[type=\"file\"]");
			this.spanFileName = <HTMLElement>this.domElement.querySelector("span.filename");

			// EVENTS
			// BUTTON START
			this.buttonNewThread.addEventListener("click", () => {
				this.buttonNewThread.classList.add("hidden");
				this.formNewThread.classList.remove("hidden");
			});

			// BUTTON ADDFILE
			this.buttonAddFile.addEventListener("click", () => {
				this.inputFileUpload.click();
			});

			// BUTTON REMOVEFILE
			this.buttonRemoveFile.addEventListener("click", () => {
				this.inputFileUpload.value = "";
				this.spanFileName.innerText = "";
				this.buttonRemoveFile.classList.add("hidden");
			});

			// FILE
			this.inputFileUpload.addEventListener("change", () => {
				// CLEANUP
				this.buttonRemoveFile.classList.add("hidden");
				this.spanFileName.innerText = "";

				//
				let filename: string = "";
				if (this.inputFileUpload.files.length != 0) {
					//
					let file: File = this.inputFileUpload.files[0];

					// CHECK FILE EXTENSION
					if (file.name.indexOf(".") == -1) {
						//
						let alertText: string = this.app.interfaceLanguage.text("application.threads.startnew.messages.extension_not_allowed");
						alertText += " (";
						alertText += this.app.environment.types.ThreadElement.allowedExtensions.join(", ");
						alertText += ")";
						//
						alert(alertText);
						//
						this.inputFileUpload.value = "";
						return;
					}
					else {
						let fileExtension: String = file.name.substring(file.name.indexOf(".")).toLowerCase();
						if (!this.app.environment.types.ThreadElement.allowedExtensions.includes(fileExtension)) {
							//
							let alertText: string = this.app.interfaceLanguage.text("application.threads.startnew.messages.extension_not_allowed");
							alertText += " (";
							alertText += this.app.environment.types.ThreadElement.allowedExtensions.join(", ");
							alertText += ")";
							//
							alert(alertText);
							//
							this.inputFileUpload.value = "";
							return;
						}
					}

					// CHECK FILE MAX LENGTH
					if (file.size > this.app.environment.types.ThreadElement.fileMaxLength) {
						let alertText: string = this.app.interfaceLanguage.text("application.threads.startnew.messages.file_too_big");
						alertText += " (" + Utilities.fileLengthToString(this.app.environment.types.ThreadElement.fileMaxLength) + ")"
						//
						alert(alertText);
						//
						this.inputFileUpload.value = "";
						return;
					}

					//
					filename = this.inputFileUpload.files[0].name + " (" + Utilities.fileLengthToString(this.inputFileUpload.files[0].size) + ")";
				}

				//
				this.spanFileName.innerText = filename;
				this.buttonRemoveFile.classList.remove("hidden");
			});


			// BUTTON CANCEL
			this.buttonCancel.addEventListener("click", () => {
				// SHOW/HIDE
				this.buttonNewThread.classList.remove("hidden");
				this.formNewThread.classList.add("hidden");
				// RESET FORM
				this.resetForm();
			});

			// BUTTON SUBMIT
			this.buttonSubmit.addEventListener("click", () => {
				this.submitForm();
			});
		}

		// NO ITEMS
		if (data.threads.length === 0) {
			let noElements: HTMLElement = document.createElement("div");
			noElements.classList.add("no-items");
			noElements.innerText = this.app.interfaceLanguage.text("application.threads.messages.no_items");
			this.domElement.appendChild(noElements);
		}

		// CYCLE
		for (let i: number = 0; i < data.threads.length; i++) {
			//
			let item: any = data.threads[i];
			let statusColor: string = Utilities.getStatusColor(item.s);
			//
			let html = `<div class="info">
		<div class="led" style="background-color:#${statusColor}"></div>
		<div class="name">${item.n}</div>
	</div>
<div class="dates">
	<div class="date created">
		<div>${this.app.interfaceLanguage.text("application.common.date_created")}</div>
		<div>${Utilities.formatDateLanguage(Utilities.timeStampToDate(item.tc), this.app.settings.interfaceLanguage)}</div>
	</div>
	<div class="date modified">
		<div>${this.app.interfaceLanguage.text("application.common.date_last_updated")}</div>
		<div>${Utilities.formatDateLanguage(Utilities.timeStampToDate(item.tm), this.app.settings.interfaceLanguage)}</div>
	</div>
</div>`;
			let itemDOM: HTMLElement = document.createElement("div");
			this.domElement.appendChild(itemDOM);
			itemDOM.classList.add("thread");
			itemDOM.innerHTML = html;
			itemDOM.addEventListener("click", () => {
				new ThreadPage(this.app, item.i);
			});

			// DATECREATED
			let dateCreated: HTMLElement = itemDOM.querySelector(".date.created div:last-child");
			if (dateCreated !== null) {
				let timeoffset: HTMLElement = TimeOffset.createElement(item.tc, "span");
				dateCreated.appendChild(timeoffset);
				timeoffset.classList.add("timeoffset");
			}

			// DATEMODIFIED
			let dateModified: HTMLElement = itemDOM.querySelector(".date.modified div:last-child");
			if (dateModified !== null) {
				let timeoffset: HTMLElement = TimeOffset.createElement(item.tm, "span");
				dateModified.appendChild(timeoffset);
				timeoffset.classList.add("timeoffset");
			}
		}
	}

	private resetForm(): void {
		this.inputTitle.value = "";
		this.inputDescription.value = "";
		this.inputFileUpload.value = "";
		this.spanFileName.innerText = "";
		this.buttonRemoveFile.classList.add("hidden");
	}

	private submitForm(): void {
		// CHECK
		if (this.isSendingData === true) {
			console.warn("form in use");
			return;
		}

		//
		let title: string = this.inputTitle.value;

		// VALIDATE
		// TODO: migliorare interazione utente
		if (title.replace(/ /g, "").length === 0) {
			alert("please insert a title / il titolo è obbligatorio");
			return;
		}
		if (this.inputDescription.value.replace(/ /g, "").length === 0) {
			alert("please insert a description / la descrizione è obbligatoria");
			return;
		}
		//
		if (title.length > 255) {
			alert("title length must be less than 255 chars / il titolo può essere al massimo di 255 caratteri");
			return;
		}

		// LOADING - START
		this.isSendingData = true;
		this.buttonSubmit.classList.add("loading");

		// FETCHER
		new Fetcher(this.app.apiURL + "?action=start-thread",
			(values: any) => {
				//console.log("success");
				//
				this.isSendingData = false;
				this.buttonSubmit.classList.remove("loading");
				//
				this.loadData();
				// TODO: sistemare messaggistica / prendere da CMS
				alert("Commento aggiunto con successo.");
			},
			(httpStatus: number, values: any) => {
				//console.log("error");
				//
				this.isSendingData = false;
				this.buttonSubmit.classList.remove("loading");
				//
				console.warn("error loading data", httpStatus, values);
				// TODO: sistemare messaggistica / prendere da CMS
				alert("Si è verificato un errore, commento non caricato.");
			}
		)
			.onProgress((percentage: number, e) => {
				console.log("uploaded " + Math.round(percentage) + "%");
			})
			.append("IDparent", this.IDparent.toString())
			.append("text", title)
			.append("description", this.inputDescription.value)
			.append("file", this.inputFileUpload.files[0])
			.send();
	}

	private set lockForm(locked: boolean) {
		//
	}
}