import { App } from "../../app.module";
import { Utilities } from "../../Utilities/Utilities";
import { ThreadPage } from "../../Pages/ThreadPage/ThreadPage";
import { Fetcher } from "../../Utilities/Fetcher";
import { TimeOffset } from "../../modules/TimeOffset";

export class Dashboard {
	//
	private autoUpdateTimeout: number;
	private autoUpdateDelay: number = 30000;	// 30 seconds

	//
	private set loadingHidden(hidden: boolean) {
		this.domElement.classList.toggle("loading", !hidden);
	}

	//
	constructor(private app: App, private domElement: HTMLElement) {
		this.loadData();
	}

	private loadData(): void {
		//
		if (!this.app.userData.isLoggedIn) return;
		//
		console.log("dashboard update");
		//
		this.stopTimeout();
		this.loadingHidden = false;
		new Fetcher(this.app.apiURL + "?action=dashboard",
			(values: any) => {
				this.loadingHidden = true;
				this.updateTimeout();
				this.renderData(values.items);
			},
			(httpStatus: number, values: any) => {
				//
				this.loadingHidden = true;
				this.updateTimeout();
				console.warn("error loadging dashboard data");
			}
		).send();
	}

	private renderData(data: any): void {
		// CLEANUP
		while (this.domElement.hasChildNodes()) this.domElement.removeChild(this.domElement.firstChild);

		// NO ITEMS
		if (data.length === 0) {
			let noElements: HTMLElement = document.createElement("div");
			noElements.classList.add("no-items");
			noElements.innerText = this.app.interfaceLanguage.text("application.dashboard.messages.no_items");
			this.domElement.appendChild(noElements);
		}

		// CYCLE
		for (let i: number = 0; i < data.length; i++) {
			//
			let item: any = data[i];
			let statusColor: string = Utilities.getStatusColor(item.sc);
			let date: Date = Utilities.timeStampToDate(item.t);
			let dateString:string = Utilities.formatDateLanguage(date, this.app.settings.interfaceLanguage);
			//
			let html = `<div><div class="led" style="background-color:#` + statusColor + `"></div></div>
<div class="project">` + item.pn + `</div>
<div class="name">` + item.tn + `</div>
<div class="date">${dateString}</div>`;
			let itemDOM: HTMLElement = document.createElement("div");
			this.domElement.appendChild(itemDOM);
			itemDOM.classList.add("thread");
			itemDOM.innerHTML = html;
			itemDOM.addEventListener("click", () => {
				new ThreadPage(this.app, item.i);
			});

			//
			let dateElement: HTMLElement = itemDOM.querySelector(".date")
			let timeOffset: HTMLElement = TimeOffset.createElement(item.t, "span");
			dateElement.appendChild(timeOffset);
			timeOffset.classList.add("timeoffset");
		}
	}

	private stopTimeout(): void {
		window.clearTimeout(this.autoUpdateTimeout);
	}

	private updateTimeout(): void {
		this.stopTimeout();
		this.autoUpdateTimeout = window.setTimeout(() => { this.loadData() }, this.autoUpdateDelay);
	}
}