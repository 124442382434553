import { App } from "../../app.module";
import { BasePage } from "../../baseclasses/BasePage";
import { FullPage } from "../../Pages/FullPage/FullPage";
import { ElementVersions } from "../../components/ElementVersions/ElementVersions";
import { Threads } from "../../components/Threads/Threads";
import { ProjectPage } from "../ProjectPage/ProjectPage";
import { SectionPage } from "../SectionPage/SectionPage";
import { Fetcher } from "../../Utilities/Fetcher";

export class ElementPage extends BasePage {
	constructor(public app: App, public IDelement: number) {
		super(app);
		this.renderPage();
	}

	public renderPage(): void {
		//
		this.parentPage = new FullPage(this.app);

		// DOM ELEMENTS
		this.rootElement = (<HTMLElement>this.parentPage.rootElement.querySelector("main"));

		// CREATE
		let html = `<div class="page-element">
	<h2 class="loading">&nbsp;</h2>
	<div class="element-container">
		<div class="versions">
			<h3>` + this.app.interfaceLanguage.text("application.versions.name") + `</h3>
			<div class="versions-container loading"></div>
		</div>
		<div class="threads">
			<h3>` + this.app.interfaceLanguage.text("application.threads.name") + `</h3>
			<div class="threads-container loading"></div>
		</div>
	</div>
</div>`;
		this.rootElement.innerHTML = html;

		// VERSIONS
		let versionsContainer: HTMLElement = this.rootElement.querySelector(".versions-container");
		new ElementVersions(this.app, versionsContainer, this.IDelement);

		// THREADS
		let threadsContainer: HTMLElement = this.rootElement.querySelector(".threads-container");
		new Threads(this.app, threadsContainer, this.IDelement);

		//
		this.loadData();
	}

	loadData(): void {
		new Fetcher(this.app.apiURL + "?action=project-structure-items&IDnode=" + this.IDelement.toString(),
			(values: any) => {
				this.renderData(values.items);
			},
			(httpStatus: number, values: any) => {
				console.warn("error loading Element data");
			}
		).send();
	}

	private renderData(data: any): void {

		// NAME
		let titleElement: HTMLElement = <HTMLElement>this.rootElement.querySelector("h2");
		titleElement.classList.remove("loading");

		// CLEANUP
		while (titleElement.hasChildNodes()) titleElement.removeChild(titleElement.firstChild);

		// PROJECT
		if (data.project !== undefined) {
			let temp: HTMLElement = document.createElement("span");
			titleElement.appendChild(temp);
			temp.classList.add("link");
			temp.innerText = data.project.n;
			temp.addEventListener("click", () => {
				new ProjectPage(this.app, data.project.i)
			});
		}

		// SLASH
		if (data.project !== undefined && data.section != undefined) {
			let slash: HTMLElement = document.createElement("span");
			titleElement.appendChild(slash);
			slash.innerText = " / ";
		}

		// SECTION
		if (data.section !== undefined) {
			let temp: HTMLElement = document.createElement("span");
			titleElement.appendChild(temp);
			temp.classList.add("link");
			temp.innerText = data.section.n;
			temp.addEventListener("click", () => {
				new SectionPage(this.app, data.section.i)
			});
		}

		// SLASH
		if (data.section !== undefined && data.element != undefined) {
			let slash: HTMLElement = document.createElement("span");
			titleElement.appendChild(slash);
			slash.innerText = " / ";
		}

		// ELEMENT
		if (data.element !== undefined) {
			let temp: HTMLElement = document.createElement("span");
			titleElement.appendChild(temp);
			temp.innerText = data.element.n;
		}
	}
}