import { App } from "../../app.module";
import { Utilities } from "../../Utilities/Utilities";
import { Fetcher } from "../../Utilities/Fetcher";
import { TimeOffset } from "../../modules/TimeOffset";

export class ElementVersions {
	//
	private isSendingData: boolean = false;

	//
	constructor(private app: App, private domElement: HTMLElement, public IDelement:number) {
		this.loadData();
	}

	public loadData(): void {
		new Fetcher(this.app.apiURL + "?action=element-versions&IDelement=" + this.IDelement.toString(),
			(values: any) => {
				this.render(values);
			},
			(httpStatus: number, values: any) => {
				console.log("error loading ElementVersions data", httpStatus);
			}
		).send();
	}

	private render(values: any): void {
		// LOADING
		this.domElement.classList.remove("loading");

		// CLEANUP
		while (this.domElement.hasChildNodes()) this.domElement.removeChild(this.domElement.firstChild);

		// NO ITEMS
		let latestMainIDnode: number = 0;
		let latestMain: number = 0;
		let latestInternal: string = null;
		//
		let versions: any = values.elementversions;
		if (versions === undefined || versions === null || versions.length === 0) {
			let noElements: HTMLElement = document.createElement("div");
			noElements.classList.add("no-items");
			noElements.innerText = this.app.interfaceLanguage.text("application.versions.messages.no_items");
			this.domElement.appendChild(noElements);
		}
		else {
			// CYCLE
			for (let i: number = 0; i < versions.length; i++) {
				let version: any = versions[i];
				let versionDOM: HTMLElement = document.createElement("div");
				this.domElement.appendChild(versionDOM);
				versionDOM.classList.add("version");
				versionDOM.classList.add("button");
				versionDOM.innerText = this.app.interfaceLanguage.text("application.versions.version_prefix") + " " + version.n;

				// FILE DATE
				if (version.f === true) {
					// DATE
					if (version.t !== null) {
						//
						let dateContainer: HTMLElement = document.createElement("span");
						versionDOM.appendChild(dateContainer);
						dateContainer.classList.add("date");
						let date: Date = Utilities.timeStampToDate(version.t);
						dateContainer.innerText = Utilities.formatDateLanguage(date, this.app.settings.interfaceLanguage);
						//
						let offset: HTMLElement = TimeOffset.createElement(version.t);
						dateContainer.appendChild(offset);
						offset.classList.add("timeoffset");
					}

					// INFO (EXTENSION / LENGTH)
					let fileInfoContainer: HTMLElement = document.createElement("span");
					versionDOM.appendChild(fileInfoContainer);
					fileInfoContainer.classList.add("file-info");
					//
					let fileLength: string = Utilities.fileLengthToString(<number>version.l);
					fileInfoContainer.innerText = `(${version.e.toUpperCase()} - ${fileLength})`;
				}

				// file
				versionDOM.addEventListener("click", (e: MouseEvent) => {
					if (version.f === true) Utilities.openFile(this.app.apiURL, version.i);
					else alert("file not found");
				});
				// LATEST
				latestMainIDnode = version.i;
				latestMain = parseInt(version.n);
				latestInternal = null;

				//
				if (version.sv != null && version.sv.length != 0) {
					for (let sv: number = 0; sv < version.sv.length; sv++) {
						//
						let subVersion: any = version.sv[sv];
						//
						let subVersionDOM: HTMLElement = document.createElement("div");
						this.domElement.appendChild(subVersionDOM);
						subVersionDOM.classList.add("subversion");
						subVersionDOM.innerText = this.app.interfaceLanguage.text("application.versions.subversion_prefix") + " " + subVersion.n;

						// FILE DATE
						if (subVersion.f === true) {
							// DATE
							if (subVersion.t !== null) {
								//
								let dateContainer: HTMLElement = document.createElement("span");
								subVersionDOM.appendChild(dateContainer);
								dateContainer.classList.add("date");
								let date: Date = Utilities.timeStampToDate(subVersion.t);
								dateContainer.innerText = Utilities.formatDateLanguage(date, this.app.settings.interfaceLanguage);
								//
								let offset: HTMLElement = TimeOffset.createElement(subVersion.t);
								dateContainer.appendChild(offset);
								offset.classList.add("timeoffset");
							}

							// INFO (EXTENSION / LENGTH)
							let fileInfoContainer: HTMLElement = document.createElement("span");
							subVersionDOM.appendChild(fileInfoContainer);
							fileInfoContainer.classList.add("file-info");
							//
							let fileLength: string = Utilities.fileLengthToString(<number>subVersion.l);
							fileInfoContainer.innerText = `(${subVersion.e.toUpperCase()} - ${fileLength})`;
						}

						// file
						subVersionDOM.addEventListener("click", (e: MouseEvent) => {
							if (subVersion.f === true) Utilities.openFile(this.app.apiURL, subVersion.i);
							else alert("file not found");
						});
						// LATEST
						latestInternal = subVersion.n;
					}
				}
			}
		}

		// EDIT
		if (values.canEditVersions === true) {
			// MAIN NEXT
			let nextMain: number = latestMain + 1;
			let nextInternal: string = null;
			if (latestInternal === null) nextInternal = "A";
			else nextInternal = String.fromCharCode(latestInternal.charCodeAt(0) + 1)

			// CONTAINER
			let html: string = `<div class="version-new">
	<div class="button createnew">${this.app.interfaceLanguage.text("application.versions.form.button_open")}</div>
	<div class="form hidden">
		<input type="hidden" name="latestMainIDnode" value="` + latestMainIDnode.toString() + `" />
		<div class="type">${this.app.interfaceLanguage.text("application.versions.form.types_label")}
			<select>
				<option value="${this.app.environment.types.Subversion.IDtype.toString()}">${this.app.interfaceLanguage.text("application.versions.form.type_internal")} ${nextInternal}</option>
				<option value="${this.app.environment.types.Version.IDtype.toString()}">${this.app.interfaceLanguage.text("application.versions.form.type_main")} ${nextMain.toString()}</option>
			</select>
		</div>

		<div class="button addfile">${this.app.interfaceLanguage.text("application.versions.form.button_addfile")}</div><input type="file" />
		<div class="filename"></div>

<div class="button submit">${this.app.interfaceLanguage.text("application.versions.form.button_submit")}</div>
	</div>
</div>`;
			this.domElement.insertAdjacentHTML("beforeend", html);

			// DOM ELEMENTS
			let createNewButton: HTMLElement = this.domElement.querySelector(".version-new .button");
			let createNewForm: HTMLElement = this.domElement.querySelector(".version-new .form");
			let createNewSelect: HTMLSelectElement = createNewForm.querySelector("select");
			let buttonAddFile: HTMLElement = <HTMLElement>createNewForm.querySelector(".button.addfile");
			let inputFileUpload: HTMLInputElement = <HTMLInputElement>createNewForm.querySelector("input[type=\"file\"]");
			let spanFileName: HTMLElement = <HTMLElement>createNewForm.querySelector(".filename");
			let buttonSubmit: HTMLElement = <HTMLElement>createNewForm.querySelector(".button.submit");

			// ADJUSTMENTS - IF NO ITEMS, ONLY MAIN REVISIONS CAN BE CREATED
			if (latestMain === 0) {
				let firstOption: HTMLOptionElement = <HTMLOptionElement>createNewSelect.querySelector("option");
				firstOption.parentElement.removeChild(firstOption);
				createNewSelect.disabled = true;
			}

			// EVENTS
			createNewButton.addEventListener("click", () => {
				createNewForm.classList.toggle("hidden");
				// BUTTON TEXT ADD / CANCEL
				createNewButton.innerText = (createNewForm.classList.contains("hidden")) ? this.app.interfaceLanguage.text("application.versions.form.button_open") : this.app.interfaceLanguage.text("application.versions.form.button_cancel");
			});

			// BUTTON ADDFILE
			buttonAddFile.addEventListener("click", () => {
				inputFileUpload.click();
			});

			// FILE
			inputFileUpload.addEventListener("change", () => {
				// CLEANUP
				spanFileName.innerText = "";

				//
				let filename: string = "";
				if (inputFileUpload.files.length != 0) {
					//
					let file: File = inputFileUpload.files[0];
					//
					let selectedIDtype: number = parseInt(createNewSelect.options[createNewSelect.selectedIndex].value);
					let itemType: any = Utilities.getTypeByID(this.app, selectedIDtype);

					// CHECK FILE EXTENSION
					if (file.name.indexOf(".") == -1) {
						//
						let alertText: string = this.app.interfaceLanguage.text("application.versions.form.messages.extension_not_allowed");
						alertText += " (";
						alertText += itemType.allowedExtensions.join(", ");
						alertText += ")";
						//
						alert(alertText);
						//
						inputFileUpload.value = "";
						return;
					}
					else {
						let fileExtension: String = file.name.substring(file.name.indexOf(".")).toLowerCase();
						if (!itemType.allowedExtensions.includes(fileExtension)) {
							//
							let alertText: string = this.app.interfaceLanguage.text("application.versions.form.messages.extension_not_allowed");
							alertText += " (";
							alertText += itemType.allowedExtensions.join(", ");
							alertText += ")";
							//
							alert(alertText);
							//
							inputFileUpload.value = "";
							return;
						}
					}

					// CHECK FILE MAX LENGTH
					if (file.size > itemType.fileMaxLength) {
						let alertText: string = this.app.interfaceLanguage.text("application.versions.form.messages.file_too_big");
						alertText += " (" + Utilities.fileLengthToString(itemType.fileMaxLength) + ")"
						//
						alert(alertText);
						//
						inputFileUpload.value = "";
						return;
					}

					//
					filename = inputFileUpload.files[0].name + " (" + Utilities.fileLengthToString(inputFileUpload.files[0].size) + ")";
				}

				//
				spanFileName.innerText = filename;
			});

			// BUTTON SUBMIT
			buttonSubmit.addEventListener("click", () => {
				this.submitForm();
			});
		}
	}

	private submitForm(): void {
		// CHECK
		if (this.isSendingData === true) {
			console.warn("form in use");
			return;
		}

		// VALUES
		let createNewForm: HTMLElement = this.domElement.querySelector(".version-new .form");
		let createNewLatestMainIDnode: HTMLSelectElement = createNewForm.querySelector("input[name=\"latestMainIDnode\"]");
		let createNewSelect: HTMLSelectElement = createNewForm.querySelector("select");
		let inputFileUpload: HTMLInputElement = <HTMLInputElement>createNewForm.querySelector("input[type=\"file\"]");
		let buttonSubmit: HTMLElement = <HTMLElement>createNewForm.querySelector(".button.submit");
		//
		let IDtype: number = parseInt(createNewSelect.options[createNewSelect.selectedIndex].value);
		let tempName: string = createNewSelect.options[createNewSelect.selectedIndex].text
		let nameItem = tempName.substring(tempName.length - 1);
		let file: File = inputFileUpload.files[0];

		// ADJUST IDparent: if creating a Subversion, pass the Version IDnode as IDparent
		let IDparent: number = this.IDelement;
		if (IDtype === this.app.environment.types.Subversion.IDtype) IDparent = parseInt(createNewLatestMainIDnode.value);
		
		// VALIDATE
		if (file === undefined) {
			alert(this.app.interfaceLanguage.text("application.versions.form.messages.file_mandatory"));
			return;
		}

		// SUBMIT
		new Fetcher(this.app.apiURL + "?action=element-version-add",
			(values: any) => {

				// LOADING - END
				this.isSendingData = false;
				buttonSubmit.classList.remove("loading");
				//
				this.loadData();
				alert(this.app.interfaceLanguage.text("application.versions.form.messages.success"));
			},
			(httpStatus: number, values: any) => {

				// LOADING - END
				this.isSendingData = false;
				buttonSubmit.classList.remove("loading");
				//
				console.log("error loading data");
				alert(this.app.interfaceLanguage.text("application.versions.form.messages.error"));

			}
		)
			.onProgress((percentage: number) => { console.warn("upload percentage ", percentage); })
			.append("IDparent", IDparent.toString())
			.append("IDtype", IDtype.toString())
			.append("name", nameItem)
			.append("file", file)
			.send();

		// LOADING - START
		this.isSendingData = true;
		buttonSubmit.classList.add("loading");
	}
}