import { App } from "../../app.module";
import { BasePage } from "../../baseclasses/BasePage";
import { FullPage } from "../FullPage/FullPage";
import { Dashboard } from "../../components/Dashboard/Dashboard";

export class DashboardPage extends BasePage {

	//
	constructor(public app: App) {
		super(app);
		this.render();
	}

	render(): void {
		//
		this.parentPage = new FullPage(this.app);

		// DOM ELEMENTS
		this.rootElement = (<HTMLElement>this.parentPage.rootElement.querySelector("main"));

		// CREATE
		let html = `<div>
	<h2>` + this.app.interfaceLanguage.text("application.dashboard.name") + `</h2>
	<div class="dashboard loading"></div>
</div>`;
		this.rootElement.innerHTML = html;

		// VERSIONS
		let dashboardPlaceholder: HTMLElement = this.rootElement.querySelector(".dashboard");
		new Dashboard(this.app, dashboardPlaceholder);
	}
}