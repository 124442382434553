import { App } from "../../app.module";
import { BasePage } from "../../baseclasses/BasePage";
import { FullPage } from "../FullPage/FullPage";
import { ElementVersions } from "../../components/ElementVersions/ElementVersions"
import { Thread } from "../../components/Thread/Thread";
import { ProjectPage } from "../ProjectPage/ProjectPage";
import { SectionPage } from "../SectionPage/SectionPage";
import { Fetcher } from "../../Utilities/Fetcher";

export class ThreadPage extends BasePage {

	//
	constructor(public app: App, public IDthread: number) {
		super(app);
		this.render();
	}

	render(): void {
		//
		this.parentPage = new FullPage(this.app);

		// DOM ELEMENTS
		this.rootElement = (<HTMLElement>this.parentPage.rootElement.querySelector("main"));

		// CREATE
		let html = `<div class="page-element">
	<h2 class="loading">&nbsp;</h2>
	<h3 class="loading">&nbsp;</h3>
	<div class="element-container no-versions">
		<div class="versions">
			<h3>` + this.app.interfaceLanguage.text("application.versions.name") + `</h3>
			<div class="versions-container loading"></div>
		</div>
		<div class="comments">
			<h3>` + this.app.interfaceLanguage.text("application.thread.name") + `</h3>
			<div class="comments-container loading"></div>
		</div>
	</div>
</div>`;
		this.rootElement.innerHTML = html;

		//
		this.loadData();
	}

	loadData(): void {
		new Fetcher(this.app.apiURL + "?action=project-structure-items&IDnode=" + this.IDthread.toString(),
			(values: any) => {
				this.renderData(values.items);
			},
			(httpStatus: number, values: any) => {
				console.warn("error loading Element data");
			}
		).send();
	}

	private renderData(data: any): void {

		// NAME
		let titleElement: HTMLHeadingElement = <HTMLHeadingElement>this.rootElement.querySelector("h2");
		titleElement.classList.remove("loading");

		// CLEANUP
		while (titleElement.hasChildNodes()) titleElement.removeChild(titleElement.firstChild);

		// PROJECT
		if (data.project !== undefined) {
			let temp: HTMLElement = document.createElement("span");
			titleElement.appendChild(temp);
			temp.classList.add("link");
			temp.innerText = data.project.n;
			temp.addEventListener("click", () => {
				new ProjectPage(this.app, data.project.i)
			});
		}

		// SLASH
		if (data.project !== undefined && data.section != undefined) {
			let slash: HTMLElement = document.createElement("span");
			titleElement.appendChild(slash);
			slash.innerText = " / ";
		}

		// SECTION
		if (data.section !== undefined) {
			let temp: HTMLElement = document.createElement("span");
			titleElement.appendChild(temp);
			temp.classList.add("link");
			temp.innerText = data.section.n;
			temp.addEventListener("click", () => {
				new SectionPage(this.app, data.section.i)
			});
		}

		// SLASH
		if (data.section !== undefined && data.element != undefined) {
			let slash: HTMLElement = document.createElement("span");
			titleElement.appendChild(slash);
			slash.innerText = " / ";
		}

		// ELEMENT
		if (data.element !== undefined) {
			let temp: HTMLElement = document.createElement("span");
			titleElement.appendChild(temp);
			temp.innerText = data.element.n;
		}


		// THREAD 
		let titleH3Element: HTMLHeadingElement = <HTMLHeadingElement>this.rootElement.querySelector("h3");
		titleH3Element.classList.remove("loading");
		titleH3Element.innerText = data.thread.n;

		// ELEMENT VERSIONS
		if (data.element !== undefined) {
			let elementContainer: HTMLElement = this.rootElement.querySelector(".element-container");
			elementContainer.classList.remove("no-versions");
			//
			let versionsContainer: HTMLElement = this.rootElement.querySelector(".versions-container");
			new ElementVersions(this.app, versionsContainer, data.element.i);
		}

		// COMMENTS
		let commentsContainer: HTMLElement = this.rootElement.querySelector(".comments-container");
		new Thread(this.app, commentsContainer, this.IDthread);
	}
}