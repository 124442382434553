export class Settings {
	//
    private localStorageKey: string = "settings";
	//
    private _interfaceLanguage: string;
    public get interfaceLanguage(): string {
        return this._interfaceLanguage;
    }
    public set interfaceLanguage(value:string) {
        this._interfaceLanguage = value;
        this.save();
	}

	//
    constructor() {
        let localStoredData: any = localStorage.getItem(this.localStorageKey);
        if (localStoredData !== undefined) {
            try {
                let localStoredDataJSON: any = JSON.parse(localStoredData);
                //
                this._interfaceLanguage = localStoredDataJSON.interfaceLanguage;
            }
            catch (e) {
                console.warn("error reading settings from localStorage: ", e);
            }
        }
    }

    public save(): void {
        //
        let stringObj: string = JSON.stringify({
            interfaceLanguage: this._interfaceLanguage
        });
        localStorage.setItem(this.localStorageKey, stringObj);
    }
}