import { Environment } from "./modules/Environment";
import { InterfaceLanguage } from "./modules/InterfaceLanguage";
import { UserData } from "./modules/UserData";
import { Settings } from "./modules/Settings";
//
import { LoginPage } from "./Pages/LoginPage/LoginPage";
import { DashboardPage } from "./Pages/DashboardPage/DashboardPage";
import { BasePage } from "./baseclasses/BasePage";
import { TimeOffset } from "./modules/TimeOffset";

export class App {
    //
    public get appName(): string {
        return "WorkFlow";
	}
	//
	public environmentType: EnvironmentType;
	public cacheRoot: CacheRoot;
    public apiURL: string;
	//
    public settings: Settings;
	public environment: Environment;
    public interfaceLanguage: InterfaceLanguage;
    public userData: UserData;
	public domReady: boolean = false;
	public timeoffset: TimeOffset;
    //
    public currentPage: BasePage;

    // DOM Elements
    public rootElement: HTMLDivElement;
    private preloader: HTMLDivElement;

	//
    public set preloaderHidden(value: boolean) {
        this.preloader.classList.toggle("hidden", value);
    }

	//
	constructor() {
		//
		let appInfo: any = JSON.parse(document.querySelector("script#app").innerHTML);
		this.environmentType = EnvironmentType[<string>appInfo.type];
        this.cacheRoot = CacheRoot[<string>appInfo.cacheRoot];
		this.apiURL = appInfo.apiURL;

		// SOME LOGGING
		//console.log("this.environmentType", this.environmentType);
		//console.log("this.cacheRoot", this.cacheRoot);
		//console.log("this.apiURL", this.apiURL);

        //
        this.settings = new Settings();
        this.environment = new Environment(this);
        this.interfaceLanguage = new InterfaceLanguage(this);
		this.userData = new UserData(this);
		this.timeoffset = new TimeOffset();

        // DOM Elements
        this.rootElement = <HTMLDivElement>document.getElementById("app-root");
        this.preloader = <HTMLDivElement>document.getElementById("preloader");

		// START LOADING
        this.initializeLoop();
    }

    public initializeLoop(): void {
        //console.log("app.initializeLoop");
        //console.log("� environment ", this.environment.initialized);
        //console.log("� interfaceLanguage ", this.interfaceLanguage.initialized);
        //console.log("� userData ", this.userData.initialized);
        //console.log("� domReady ", this.domReady);

		// CHECK ENVIRONMENT
        if (!this.environment.initialized) {
            this.environment.initialize();
            return;
        }
		// CHECK INTERFACELANGUAGE
        if (!this.interfaceLanguage.initialized) {
            this.interfaceLanguage.initialize();
            return;
        }
		// CHECK USERDATA
        if (!this.userData.initialized) {
            this.userData.initialize();
            return;
        }
        // CHECK DOM READY
        if (!this.domReady)
            return;

		// START
		this.start();
    }

    private start(): void {
        //
        if (this.userData.isLoggedIn) new DashboardPage(this);
        else new LoginPage(this);

        // HIDE PRELOADER
        this.preloaderHidden = true;
    }
}

export enum EnvironmentType {
	Development = "DEVELOPMENT",
	Testing = "TESTING",
	Production = "PRODUCTION"
}

export enum CacheRoot {
	Preview = "PREVIEW",
	Internal = "INTERNAL"
}